import React from "react";

import EventTabs from "./components/EventTabs";
import AllEvents from "./components/AllEvents";

const Events = () => {
  return <AllEvents  />;
};

export default Events;
