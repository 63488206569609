import React from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import request from "../../../utilities/requests";
import formatCamelCase from "../../../utilities/formatCamelCase";

const messages = [
  "Your ideal match is waiting, and all it takes is a few questions",
  "Answer these compatibility questions and open the door to meaningful connections",
  "Love is priceless, and so are our compatibility questions",
];

const CategoryCards = ({ stats, handleClick }) => {
  const theme = useTheme();
  const [categories, setCategories] = React.useState([]);
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
    if (!message){
      generateMessage();
    }
  });

  const generateMessage = () => {
     const min = 0;
     const max = messages.length - 1;
     const val = Math.floor(Math.random() * (max - min + 1) + min);
     setMessage(messages[val]);

  };

  const getCategories = async () => {
    const result = await request("matchmaking/question/categories");
    if (result.success) {
      setCategories(result.categories);
    }
  };

  const getStat = (category, stat) => {
    try {
      if (stats) {
        return `${stats[category][stat]}%`;
      }
      return "";
    } catch (e) {
      return "";
    }
  };

  return (
    <Grid
      container
      // direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      style={{ width: "100%" }}
    >
      <Grid item style={{ width: 100, height: 100 }}>
        <CircularProgressbar
          value={stats ? stats.totalPercentage : 0}
          text={`${stats ? stats.totalPercentage : 0}%`}
          styles={buildStyles({
            pathColor: theme.palette.primary.main,
            textColor: theme.palette.primary.main,
            trailColor: "#d6d6d6",
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center">
          {message}
        </Typography>
      </Grid>
      {categories.map((category) => {
        return (
          <Grid item key={category} xs={12}>
            <Paper
              elevation={2}
              style={{
                width: "100%",
                padding: 10,
              }}
            >
              <Grid container direction="column" spacing={1}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ paddingLeft: 5 }}>
                    {formatCamelCase(category)}
                  </Typography>
                  <Typography style={{ paddingRight: 5 }}>
                    {getStat(category, "percentage")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => handleClick("answerQuestions", category)}
                    disabled={
                      getStat(category, "percentage") === "100%" ? true : false
                    }
                  >
                    answer questions
                  </Button>
                  <Button
                    onClick={() => handleClick("viewAnswers", category)}
                    disabled={
                      getStat(category, "percentage") === "0%" ? true : false
                    }
                  >
                    view answers
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CategoryCards;
