import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

const CreateDateDialog = ({ open, close, next }) => {
  const [checked, setChecked] = React.useState(false);

  const handleCheck = () => {
    if (!checked) {
      setChecked(true);
      localStorage.setItem("createDateReminder", "false");
    } else {
      setChecked(false);
      localStorage.removeItem("createDateReminder");
    }
  };
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle id="alert-dialog-title">{"Creating a new date"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new date manually will delete the date that you currently
          have set up.
        </DialogContentText>
        <br />
        <DialogContentText>
          Please only proceed with creating dates manually if your date did not
          show up or if the event organizer instructed you to do so.{" "}
        </DialogContentText>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Checkbox size="small" checked={checked} onClick={handleCheck} />
          <Typography variant="subtitle2">Don't show this again</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            close();
            next();
          }}
          autoFocus
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDateDialog;
