import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PasswordValidator from "password-validator";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AuthContext from "../../../contexts/AuthContext";
import { confirmForgot } from "../../../utilities/authFlow";

const ConfirmForgot = ({ setPage }) => {
  const theme = useTheme();
  const { email, setEmail, appStyle, appConfig } =
    React.useContext(AuthContext);
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [code, setCode] = React.useState("");
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [error, setError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = React.useState(null);
  const [codeError, setCodeError] = React.useState(false);

  const validatePassword = () => {
    console.log("validating password");
    const schema = new PasswordValidator();
    schema
      .is()
      .min(8, "Password is too short - min 8")
      .has()
      .uppercase(1, "At least 1 uppercase letter required")
      .has()
      .lowercase(1, "At least 1 lowercase letter required")
      .has()
      .digits(1, "At least 1 number required")
      .has()
      .symbols(1, "At least 1 symbol required");

    const valid = schema.validate(password, { details: true });
    console.log(`password valid: ${valid}`);
    if (valid.length > 0) {
      setPasswordError(valid[0].message);
      return;
    }
    if (valid.length === 0 && passwordError) {
      setPasswordError(null);
    }
  };

  const validatePasswordConfirm = () => {
    console.log("validating password confirm");
    if (password !== password2) {
      setPasswordConfirmError(true);
    } else {
      setPasswordConfirmError(null);
    }
  };

  const handleReset = async () => {
    if (passwordError || passwordConfirmError) {
      return;
    }

    try {
      const result = await confirmForgot(email, password, code);
      if (result.success) {
        setError(null);
        setCodeError(null);
        setPage("success");
      } else {
        if (result.error == "Invalid code. Please try again.") {
          setCodeError(result.error);
        } else {
          setError(result.error);
        }
      }
    } catch (e) {
      var error = `Error resetting password. Error: ${e.message}`;
      console.error(error);
      setError(error);
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Paper elevation={3} style={{ width: "90%", padding: 10 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginTop: 20 }}>
                <Typography variant="h5" align="center">
                  Create a new password
                </Typography>
              </Grid>
              <Grid item style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                <Typography variant="subtitle1" align="center">
                  Please enter the 6 digit code we just emailed to you. It may
                  take a few moments to arrive. Please check your spam folder as
                  well.
                </Typography>
              </Grid>
              {error ? (
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" style={{ color: theme.palette.primary.main }}>
                    {error}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  autoFocus
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  style={{ width: "90%" }}
                  sx={{
                    fieldset: { borderColor: "red" },
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  autoFocus
                  label="Code"
                  variant="outlined"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter your 6 digit code"
                  style={{ width: "90%" }}
                  sx={{
                    fieldset: { borderColor: "red" },
                  }}
                  error={codeError}
                  helperText={
                    codeError ? "Invalid code. Please try again." : ""
                  }
                  autoComplete="off"
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  margin="dense"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={validatePassword}
                  error={passwordError ? true : false}
                  helperText={passwordError ? passwordError : ""}
                  placeholder="Enter your password"
                  style={{ width: "90%" }}
                  type={passwordVisibility ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }
                      >
                        {passwordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  margin="dense"
                  label="Password"
                  variant="outlined"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  onBlur={validatePasswordConfirm}
                  error={passwordConfirmError ? true : false}
                  helperText={
                    passwordConfirmError ? "Your passwords do not match" : ""
                  }
                  placeholder="Confirm your password"
                  style={{ width: "90%" }}
                  type={passwordVisibility ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }
                      >
                        {passwordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    height: 40,
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={handleReset}
                >
                  reset
                </Button>
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "90%", height: 40, color: theme.palette.primary.main }}
                  onClick={() => setPage("login")}
                >
                  back to login
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="center"
              style={{ color: "#353535" }}
              onClick={() => window.open(appConfig?.privacyPolicy, "_blank")}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmForgot;
