import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";

import request from "../../../../utilities/requests";

const TextQuestion = ({ questionData }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [value, setValue] = React.useState(questionData?.answer?.value);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData);
    }
  }, [questionData]);

  const handleChange = (val) => {
    setValue(val);
    if (!changed) {
      setChanged(true);
    }
  };

  const handleSave = async () => {
    let val = Math.round(value / 10);
    if (val === 0) {
      val = 1;
    }
    const body = {
      questionId: question._id,
      answer: value,
    };
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      setChanged(false);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <TextField fullWidth multiline rows={10} value={value} onChange={(e) => handleChange(e.target.value)} style={{marginBottom: 10}}/>
      </Grid>
      {changed ? (
        <Grid item xs={10}>
          <Button
            variant="contained"
            style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            onClick={handleSave}
          >
            save
          </Button>
        </Grid>
      ) : <></>}
    
    </Grid>
  );
};

export default TextQuestion;
