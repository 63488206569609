import React from "react";

import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Layout = ({ children, user }) => {
  const [value, setValue] = React.useState(1);
  const [loaded, setLoaded] = React.useState(null);

React.useEffect(() => {
  if (!loaded) {
    const hash = window.location.hash;
    let val = 0;
    if (hash === "#/myevents" || hash === '#/' || hash === '') {
      val = 1;
    } else if (hash === "#/profile") {
      val = 2;
    }
    setValue(val);
    // console.log(`Screen: ${val}`)

  }
})

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", paddingTop: 20,scrollPaddingBottom: 250 }}>
      <main style={{height:'100%'}}>
        {children}
      </main >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        style={{position:'fixed', bottom:0, width:'100%', borderTop: '1px solid lightgrey', boxShadow: '0px -1px 9px lightgrey'}}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/events"/>
        <BottomNavigationAction label="My Events" icon={<EventIcon />} component={Link} to="/"/>
        <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} component={Link} to="/profile"/>
      </BottomNavigation>
    </div>
  );
};

export default Layout;
