import React from "react";
import ReactGA from "react-ga4";
import { createTheme, ThemeProvider } from "@mui/material";
import axios from "axios";

import "@aws-amplify/ui-react/styles.css";

// import io from "socket.io-client";
import AuthContext from "./contexts/AuthContext";
import Router from "./Router";

import "./App.css";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

// const socket = io(process.env.REACT_APP_TNFT_SERVER);


function App() {
  const [signedIn, setSignedIn] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [token, setToken] = React.useState(null);
  const [appStyle, setAppStyle] = React.useState({});
  const [appConfig, setAppConfig] = React.useState({});
  const [orgId, setOrgId] = React.useState(null);

  React.useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      // console.log("requesting notifications");
      Notification.requestPermission();
    }
  });

  React.useEffect(() => {
    const getStyles = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_TNFT_SERVER}/style?url=${window.location.host}`);
        if (result?.data?.success) {
          setAppStyle(result?.data?.style);
          if (result?.data?.style?.favicon) {
            let fav = document.getElementById("favicon");
            fav.setAttribute("href", result.data.style.favicon)
          }
          if (result?.data?.style?.title) {
            document.title = result.data.style.title;
          }
          if (result?.data?.config) {
            setAppConfig(result.data.config)
          }
          if (result?.data?.orgId) {
            console.log(result.data.orgId)
            setOrgId(result.data.orgId)
          }
        }
      } catch (e) {
        console.error(e.message);
        return;
      }
    };
    getStyles();
  },[]);

  React.useEffect(() => {
    const si = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
    );
    if (si === "true") {
      setSignedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ signedIn, setSignedIn, email, setEmail, token, setToken, appStyle, appConfig, orgId }}
    >
      <Router />
    </AuthContext.Provider>
  );
}

export default App;
