import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as EmailValidator from "email-validator";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";

import { forgot } from "../../../utilities/authFlow";

const Forgot = ({ setPage }) => {
  const theme = useTheme();
  const { email, setEmail, appStyle, appConfig } = React.useContext(AuthContext);
  const [error, setError] = React.useState("");

  const validateEmail = () => {
    console.log("validating email");
    const valid = EmailValidator.validate(email);
    if (!valid) {
      setError('Please enter a valid email');
      return false
    }
    if (valid && error) {
      setError(null);
    }
    return true
  };

  const handleSendCode = async () => {

    if (!validateEmail()) {
      return
    }
    if (error) {
      return
    }

    try { 
      const result = await forgot(email);
      if (result.success) {
        setPage('confirm-forgot')
      } else {
        setError(result.error);
      }
    
    } catch (e) {
      var error = (`Error requesting reset code. Error: ${e.message}`);
      console.error(error);
      setError(error);
    }
    

   
  };



  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Paper elevation={3} style={{ width: "90%", padding: 10 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginTop: 20, marginBottom: 5 }}>
                <Typography variant="h5" align="center">
                  Reset Password
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: 5, marginBottom: 15 }}>
                <Typography variant="body1" align="center">
                  Please enter your email so that we can send you a reset code.
                </Typography>
              </Grid>
              {error ? (
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="subtitle2" style={{ color: theme.palette.primary.main }}>
                    {error}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  autoFocus
                  label="Enter your email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // placeholder="Enter your email"
                  style={{ width: "90%" }}
                  sx={{
                    fieldset: { borderColor: "red" },
                  }}
                  autoComplete="off"
                />
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    height: 40,
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={handleSendCode}
                >
                  send code
                </Button>
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "90%", height: 40, color: theme.palette.primary.main }}
                  onClick={() => setPage("login")}
                >
                  back to login
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
          <Typography 
            align="center" 
            style={{ color: "#353535", marginBottom: 50, cursor: 'pointer' }}
            onClick={() => window.open(appConfig?.privacyPolicy, '_blank')}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    // <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
    //   <div>
    //     <img src={Logo} height="200px" />
    //   </div>
    //   <div>
    //     <Paper elevation={3} style={{ width: '100%', height: 400, padding: 10 }}>
    //       <Grid container direction="column">
    //         <Grid item>
    //           <Typography>Sign into your account</Typography>
    //         </Grid>
    //       </Grid>
    //     </Paper>
    //   </div>
    //   <div>
    //     bottom
    //   </div>
    // </div>
  );
};

export default Forgot;
