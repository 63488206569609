import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AuthContext from "../../../contexts/AuthContext";

import "./success.css";

const Success = ({ setPage }) => {
  const theme = useTheme();
  const { appStyle } = React.useContext(AuthContext);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Success!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Account created successfully
        </Typography>
      </Grid>
      <Grid item>
        <div className="wrapper">
          {" "}
          <svg className="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
          </svg>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: 20 }}>
        <Typography variant="body1" align="center">
          Please head back to the login screen
        </Typography>
      </Grid>
      <Grid item style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ width: "100%", height: 40, color: theme.palette.primary.main, marginBottom: 50 }}
          onClick={() => setPage("login")}
          variant="outlined"
        >
          back to login
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="center"
              style={{ color: "#353535", marginBottom: 50 }}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Success;
