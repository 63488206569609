import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DateSelector from "../../DateSelector";
import request from "../../../utilities/requests";

const PairingProfile = ({
  pairing,
  feedback,
  canMoveForward,
  canMoveBackward,
  loading,
}) => {
  const [data, setData] = React.useState({ name: "", table: "" });
  const [imageUrl, setImageUrl] = React.useState(null);
  const [dateSelectorOpen, setDateSelectorOpen] = React.useState(false);

  React.useEffect(() => {
    if (pairing) {
      setData({
        name: pairing.person?.profile?.firstName,
        table: pairing.table,
        round: pairing.round,
      });

      if (pairing.person?.profile?.profileImage) {
        getProfilePic(pairing.person._id);
      } else {
        setImageUrl(null);
      }
    }
  }, [pairing]);

  const handleClick = (btn) => {
    if (
      typeof pairing?.like === "undefined" &&
      btn === "next" &&
      !pairing?.noPairing &&
      pairing?.status !== "pending"
    ) {
      alert("Please mark your match before proceeding to the next round.");
      return;
    }

    feedback(btn);
  };

  const getProfilePic = async (userId) => {
    const result = await request(`profile/photo?userId=${userId}`);
    if (result.success) {
      setImageUrl(result.url);
    }
  };

  const renderName = () => {
    if (!data.name && !data.round) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (!data.name && data.round) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography gutterBottom variant="h6" align="center">
              You have a break this round.
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography align="center">
              Have you been placed in a wildcard round?
            </Typography>
            <Button variant="outlined" style={{marginTop: 10}} onClick={(() => setDateSelectorOpen(true))}>Select a Date</Button>
          </Grid>
        </Grid>
      );
    } else {
      if (imageUrl) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src={imageUrl} style={{ width: 120, height: 120 }} />
            <Typography gutterBottom variant="h5" align="center">
              {data.name}
            </Typography>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccountCircleIcon
            style={{ width: 100, height: 100, color: "grey" }}
          />
          <Typography gutterBottom variant="h5" align="center">
            {data.name}
          </Typography>
        </div>
      );
    }
  };

  const renderResult = () => {
    if (pairing?.like === true) {
      return (
        <Grid item style={{ marginTop: 10 }}>
          <Avatar style={{ height: 40, width: 40, backgroundColor: "#68C371" }}>
            <FavoriteIcon style={{ height: 20, width: 20 }} />
          </Avatar>
        </Grid>
      );
    } else if (pairing?.like === false) {
      return (
        <Grid item style={{ marginTop: 10 }}>
          <Avatar style={{ height: 40, width: 40, backgroundColor: "#E36262" }}>
            <CloseIcon style={{ height: 20, width: 20 }} />
          </Avatar>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        {canMoveBackward ? (
          <Button
            style={{ borderRadius: "50%" }}
            onClick={() => handleClick("back")}
          >
            <ArrowForwardIosIcon
              className="round-button"
              style={{
                transform: "rotate(180deg)",
                color: "#4e4e4e",
                height: 60,
                width: 60,
              }}
            />
          </Button>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={8}>
        <Paper height={3} style={{ minHeight: 200, padding: 20 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%", height: "100%" }}>
              {renderName()}
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Typography gutterBottom variant="h6" align="center">
                {data.table ? "Table" : ""} {data.table}
              </Typography>
            </Grid>
            {renderResult()}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        {canMoveForward ? (
          <Button
            style={{ borderRadius: "50%" }}
            onClick={() => handleClick("next")}
          >
            <ArrowForwardIosIcon
              className="round-button"
              style={{ color: "#4e4e4e", height: 60, width: 60 }}
            />
          </Button>
        ) : (
          <></>
        )}
      </Grid>
      <DateSelector 
        open={dateSelectorOpen}
        close={() => setDateSelectorOpen(false)}
        round={data?.round}
        pairing={pairing}
      />
    </Grid>
  );
};

export default PairingProfile;
