import React from "react";
import ReactGA from 'react-ga4';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


import request from "../../utilities/requests";

const ContactUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/contactus", title: "ContactUs" });
  },[])

  React.useEffect(() => {
    if (location?.state?.subject && !subject) {
      setSubject(location.state.subject);
    }
  }, [location]);

  const handleSend = async () => {
    if (!subject) {
      alert("Please enter a subject.");
      return;
    } else if (!message) {
      alert("Please enter a message");
      return;
    }

    const body = {
      subject,
      message,
    };
    const result = await request("contactus", "post", body);
    if (result.success) {
      alert("Message sent!");
      navigate("/");
    }
  };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Contact Us
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography align="center" variant="subtitle1">
          Tell us what's on your mind. We will do our best to get back to you
          within 24 hours.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <FormControl fullWidth>
          <InputLabel>Subject</InputLabel>
          <Select
            value={subject}
            label="Subject"
            style={{ width: "100%" }}
            onChange={(e) => setSubject(e.target.value)}
          >
            <MenuItem value={"Speed Dating"}>Speed Dating</MenuItem>
            <MenuItem value={"Matchmaking"}>Matchmaking</MenuItem>
            <MenuItem value={"I Can't See My Event"}>
              I Can't See My Event
            </MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={10} style={{ marginBottom: 20 }}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Message"
          multiline
          rows={10}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Grid>
      <Grid item xs={10}>
        <Button variant="contained" fullWidth onClick={handleSend}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
