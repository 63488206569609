import React from "react";
import ReactGA from "react-ga4";
import request from "../../../utilities/requests";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";
import { AppContext } from "../../../contexts/AppContext";
import ConfirmRedirectDialog from "./ConfirmRedirectDialog";

const AllEvents = () => {
  // const [events, setEvents] = React.useState([]);
  const { appConfig } = React.useContext(AuthContext);
  const { events, setEvents } = React.useContext(AppContext);
  const [loaded, setLoaded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [eventUrl, setEventUrl] = React.useState(null);
  const [filters, setFilters] = React.useState({
    Pairing: true,
    SportsAndWellness: false,
    ArtsAndEntertainment: false,
  });
  const [showFilters, setShowFilters] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/events", title: "AllEvents" });
  }, []);

  React.useEffect(() => {
    if (!loaded) {
      getAllEvents();
      setLoaded(true);
      window.scrollTo(0, 0);
    }
  });

  React.useEffect(() => {
    if (appConfig?.pairingOnly === false) {
      setShowFilters(true);
    }
  }, [appConfig]);

  const getAllEvents = async () => {
    const result = await request("events");
    if (result.success) {
      setEvents(result.events);
    }
  };

  const handleFilters = (filter) => {
    let fs = filters;
    for (var key of Object.keys(fs)) {
      fs[key] = false;
    }
    fs[filter] = true;
    setFilters({ ...fs });
  };

  const handleOpen = (url) => {
    setOpen(true);
    setEventUrl(url);
  };
  const handleClose = () => {
    setOpen(false);
    setEventUrl(null);
  };

  const handleOpenLink = () => {
    window.open(eventUrl, "_blank", "noreferrer");
    setOpen(false);
    setEventUrl(null);
    ReactGA.event({
      category: "events",
      action: "goto_eventbrite",
    });
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let dateString = d.toDateString();
    let hours = d.getHours();
    let minutes = d.getMinutes();

    let timeString;
    if (hours > 12 && minutes === 0) {
      timeString = `${hours - 12} PM`;
    } else if (hours > 12 && minutes > 0) {
      timeString = `${hours - 12}:${minutes} PM`;
    } else if (hours <= 12 && minutes === 0) {
      timeString = `${hours} AM`;
    } else if (hours <= 12 && minutes > 0) {
      timeString = `${hours}:${minutes} AM`;
    }

    return `${dateString} @ ${timeString}`;
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={3}
      style={{ marginBottom: 50, padding: "20px 20px 200px 20px" }}
    >
      {showFilters === true ? (
        <Grid
          item
          style={{
            width: "100%",
            display: "flex",
            direction: "row",
            justifyContent: "center",
            gap: 10,
            marginBottom: 20,
          }}
          xs={12}
        >
          <Chip
            label="Pairing"
            variant={filters.Pairing ? "" : "outlined"}
            color="primary"
            onClick={() => handleFilters("Pairing")}
          />
          <Chip
            label="Sports & Wellness"
            variant={filters.SportsAndWellness ? "" : "outlined"}
            color="primary"
            onClick={() => handleFilters("SportsAndWellness")}
          />
          <Chip
            label="Arts & Entertainment"
            variant={filters.ArtsAndEntertainment ? "" : "outlined"}
            color="primary"
            onClick={() => handleFilters("ArtsAndEntertainment")}
          />
        </Grid>
      ) : (
        <></>
      )}
      {events.map((event) => {
        if (filters[event.eventType]) {
          return (
            <Grid key={event._id} item>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={event.imageUrl}
                  title={event.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {event.name}
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
                    {formatDate(event.date)}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {event.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => handleOpen(event.eventbriteUrl)}
                  >
                    Register
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        }

        // return <div key={event._id}></div>
      })}
      <ConfirmRedirectDialog
        open={open}
        close={handleClose}
        confirm={handleOpenLink}
      />
    </Grid>
  );
};

export default AllEvents;
