import React from "react";
import { Grid, Typography, Avatar, Skeleton } from "@mui/material";
import axios from "axios";

const iconSize = 120;

const ProfileHeader = ({ profile, refresh }) => {
  const inputFile = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const body = new FormData();
    body.append("photo", file);
    body.append("Content-Type", "image/jpg");
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_TNFT_SERVER}/profile/photo`,
      method: "post",
      data: body,
      headers: { "Content-Type": "multipart/form-data", Accesstoken: token },
    })
      .then((response) => {
        refresh();
        setLoading(false);
      })
      .catch((response) => {
        console.error(response);
        setLoading(false);
      });
  };

  return (
    <Grid item>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="circular" width={iconSize} height={iconSize} />
          ) : (
            <Avatar
              src={profile.imageUrl}
              style={{ width: iconSize, height: iconSize, color: "grey" }}
              onClick={() => inputFile.current.click()}
            />
          )}
        </Grid>
        <Grid item style={{ paddingTop: 0, cursor: "pointer" }} onClick={() => inputFile.current.click()}>
          <Typography
            variant="overline"
            align="center"
          >
            edit
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            {profile ? profile.firstName : ""} {profile ? profile.lastName : ""}
          </Typography>
        </Grid>
      </Grid>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
    </Grid>
  );
};

export default ProfileHeader;
