import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const DoneDialog = ({open, close}) => {



    return (
        <Dialog
        open={open}
        onClose={() =>  close()}
      >
        <DialogTitle id="alert-dialog-title">
          {"Whoo Hoo!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            The event is now complete! We hope you had fun and made some great matches.
          </DialogContentText>
          <br />
          <DialogContentText >
            We will connect you with your matches in 24 hours via email or sms.
          </DialogContentText>
          <br />
          <DialogContentText >
          Until then, you can go back through your matches and change your responses. 
          </DialogContentText>
          <br />
          <DialogContentText >
            If you still have questions, please do not hestiate to ask an event host.
          </DialogContentText>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>  close()} autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    )

}

export default DoneDialog;