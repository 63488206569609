import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AuthContext from "../../../contexts/AuthContext";
import { login } from "../../../utilities/authFlow";

const Login = ({ setPage }) => {
  const theme = useTheme();
  const { setSignedIn, email, setEmail, appStyle, appConfig } = React.useContext(AuthContext);
  // const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const reEmail = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.email`
    );
    if (reEmail) {
      setRememberMe(true);
      setEmail(reEmail);
    }
  }, []);

  const handleLogin = async () => {
    const result = await login(email, password);
    if (result.success) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  };

  const handleRememberMe = () => {
    if (!rememberMe) {
      setRememberMe(true);
      localStorage.setItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.email`,
        email
      );
    } else {
      setRememberMe(false);
      localStorage.removeItem(`${process.env.REACT_APP_COGNITO_CLIENT_ID}.email`);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <form style={{ display: "flex", justifyContent: "center" }}> */}
        <Paper elevation={3} style={{ width: "100%", padding: 10 }}>
          <Grid container direction="column" spacing={3}>
            <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="h5" align="center">
                Sign into your account
              </Typography>
            </Grid>
            {error ? (
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.main, paddingLeft: 20  }}>
                  {error}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                autoFocus
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
                style={{ width: "90%" }}
                inputProps={{autoCapitalize: 'none'}}
                sx={{
                  fieldset: { borderColor: "red" },
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                margin="dense"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // error={error}
                // helperText={error ? error : ""}
                placeholder="Your password"
                style={{ width: "90%" }}
                type={passwordVisibility ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                    >
                      {passwordVisibility ? <VisibilityOff style={{cursor:'pointer'}}/> : <Visibility style={{cursor:'pointer'}}/>}
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              style={{
                paddingTop: 0,
                marginBottom: -10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                style={{ paddingLeft: 15 }}
                size="small"
                checked={rememberMe}
                onChange={handleRememberMe}
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <Typography variant="subtitle2">Remember me?</Typography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ width: "90%", height: 40, backgroundColor: theme.palette.primary.main }}
                onClick={handleLogin}
              >
                Sign in
              </Button>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                style={{
                  width: "90%",
                  height: 40,
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                onClick={() => setPage("signup")}
              >
                create account
              </Button>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ width: "90%", height: 40, color: theme.palette.primary.main}}
                onClick={() => setPage("forgot")}
              >
                reset password
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {/* </form> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2} >
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography 
            align="center" 
            style={{ color: "#353535", marginBottom: 50, cursor: 'pointer' }}
            onClick={() => window.open(appConfig?.privacyPolicy, '_blank')}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    // <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
    //   <div>
    //     <img src={Logo} height="200px" />
    //   </div>
    //   <div>
    //     <Paper elevation={3} style={{ width: '100%', height: 400, padding: 10 }}>
    //       <Grid container direction="column">
    //         <Grid item>
    //           <Typography>Sign into your account</Typography>
    //         </Grid>
    //       </Grid>
    //     </Paper>
    //   </div>
    //   <div>
    //     bottom
    //   </div>
    // </div>
  );
};

export default Login;
