import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import * as EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import parsePhoneNumber from "libphonenumber-js";
import AuthContext from "../../../contexts/AuthContext";
import { signup } from "../../../utilities/authFlow";


const Signup = ({ setPage }) => {
  const theme = useTheme();
  const { email, setEmail, appStyle, orgId, appConfig } = React.useContext(AuthContext);
  // const [email, setEmail] = React.useState("");
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [rightIcon, setRightIcon] = React.useState("eye");
  const [error, setError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = React.useState(null);
  const [phoneError, setPhoneError] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const validateEmail = () => {
    console.log("validating email");
    const valid = EmailValidator.validate(email);
    if (!valid) {
      setEmailError(true);
      return;
    }
    if (valid && emailError) {
      setEmailError(null);
    }
  };

  const validatePassword = () => {
    console.log("validating password");
    const schema = new PasswordValidator();
    schema
      .is()
      .min(8, "Password is too short - min 8")
      .has()
      .uppercase(1, "At least 1 uppercase letter required")
      .has()
      .lowercase(1, "At least 1 lowercase letter required")
      .has()
      .digits(1, "At least 1 number required")
      .has()
      .symbols(1, "At least 1 symbol required");

    const valid = schema.validate(password, { details: true });
    console.log(`password valid: ${valid}`);
    if (valid.length > 0) {
      setPasswordError(valid[0].message);
      return;
    }
    if (valid.length === 0 && passwordError) {
      setPasswordError(null);
    }
  };

  const validatePasswordConfirm = () => {
    console.log("validating password confirm");
    if (password !== password2) {
      setPasswordConfirmError(true);
    } else {
      setPasswordConfirmError(null);
    }
  };

  const validatePhone = () => {
    console.log("validating phone");
    if (!phone) {
      setPhoneError(true);
      return false;
    }
    const phoneNumber = parsePhoneNumber(phone, "US");
    if (!phoneNumber.isValid()) {
      console.log("Invalid phone");
      setPhoneError(true);
      return false;
    }
    if (phoneNumber.isValid() && phoneError) {
      setPhoneError(null);
    }
    return true;
  };

  const handleSignup = async () => {
    if (!validatePhone()) {
      return;
    }
    if (emailError || passwordError || passwordConfirmError) {
      return;
    }

    if (!checked) {
      setError("Please confirm you agree to our Privacy Policy");
      return;
    }

    const phoneNumber = parsePhoneNumber(phone, "US");

    try {
      const result = await signup(email, password, phoneNumber.number, orgId);
      if (result.success) {
        console.log("signup success");
        setPage("confirm");
      } else {
        setError(result.error);
      }
    } catch (e) {
      var error = `Unknown error: ${e.message}`;
      console.log(error);
      setError(error);

      return { success: false, error };
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Paper elevation={3} style={{ width: "100%", padding: 10 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography variant="h5" align="center">
                  Create a new account
                </Typography>
              </Grid>
              {error ? (
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "center"}}
                >
                  <Typography variant="subtitle1" style={{ color: theme.palette.primary.main,  paddingLeft: 20  }}>
                    {error}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  autoFocus
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  style={{ width: "90%"}}
                  sx={{
                    fieldset: { borderColor: "red" },
                  }}
                  inputProps={{autoCapitalize: 'none'}}
                  autoComplete="off"
                  onBlur={validateEmail}
                  error={emailError ? true : false}
                  helperText={emailError ? "Please enter a valid email" : ""}
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  margin="dense"
                  label="Enter your password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // error={error}
                  // helperText={error ? error : ""}
                  // placeholder="Enter your password"
                  style={{ width: "90%" }}
                  type={passwordVisibility ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }
                      >
                        {passwordVisibility ? (
                          <VisibilityOff style={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility style={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                  onBlur={validatePassword}
                  error={passwordError ? true : false}
                  helperText={passwordError ? passwordError : ""}
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  margin="dense"
                  label="Confirm your password"
                  variant="outlined"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  style={{ width: "90%" }}
                  type={passwordVisibility ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }
                      >
                        {passwordVisibility ? (
                          <VisibilityOff style={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility style={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                  onBlur={validatePasswordConfirm}
                  error={passwordConfirmError ? true : false}
                  helperText={
                    passwordConfirmError ? "Your passwords do not match" : ""
                  }
                />
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                  style={{ width: "90%" }}
                  sx={{
                    fieldset: { borderColor: "red" },
                  }}
                  autoComplete="off"
                  onBlur={validatePhone}
                  error={phoneError ? true : false}
                  inputProps={{ inputMode: 'numeric' }}
                  helperText={
                    phoneError ? "Please enter a valid phone number" : ""
                  }
                />
              </Grid>
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "4%",
                  }}
                >
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <Typography variant="subtitle2">
                    I have read and agree to the{" "}
                    <a
                      href="https://thenextfunthing.com/pages/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    height: 40,
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={handleSignup}
                >
                  create account
                </Button>
              </Grid>
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "90%", height: 40, color: theme.palette.primary.main }}
                  onClick={() => setPage("login")}
                >
                  back to login
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="center"
              style={{ color: "#353535", marginBottom: 50 }}
              onClick={() => window.open(appConfig?.privacyPolicy, '_blank')}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    // <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
    //   <div>
    //     <img src={Logo} height="200px" />
    //   </div>
    //   <div>
    //     <Paper elevation={3} style={{ width: '100%', height: 400, padding: 10 }}>
    //       <Grid container direction="column">
    //         <Grid item>
    //           <Typography>Sign into your account</Typography>
    //         </Grid>
    //       </Grid>
    //     </Paper>
    //   </div>
    //   <div>
    //     bottom
    //   </div>
    // </div>
  );
};

export default Signup;
