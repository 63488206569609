import React from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import request from "../../../utilities/requests";
import {
  Grid,
  Card,
  Avatar,
  CircularProgress,
  Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const EventResults = () => {
  const location = useLocation();
  const [event, setEvent] = React.useState(null);
  const [pairings, setPairings] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/eventresults", title: "EventResults" });
  },[])

  React.useEffect(() => {
    if (location?.state?.event && !event) {
      setEvent(location.state.event);
      getPairings(location.state.event);
    }
  }, [location]);

  const getPairings = async (event) => {
    const result = await request(`pairing/results?eventId=${event._id}`);
    if (result.success) {
      setPairings(result.pairings);
    }
    setLoaded(true);
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let dateString = d.toDateString();
    let hours = d.getHours();
    let minutes = d.getMinutes();

    let timeString;
    if (hours > 12 && minutes === 0) {
      timeString = `${hours - 12} PM`;
    } else if (hours > 12 && minutes > 0) {
      timeString = `${hours - 12}:${minutes} PM`;
    } else if (hours <= 12 && minutes === 0) {
      timeString = `${hours} AM`;
    } else if (hours <= 12 && minutes > 0) {
      timeString = `${hours}:${minutes} AM`;
    }

    return `${dateString} @ ${timeString}`;
  };

  if (!loaded) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ height: 100, width: 100 }} />
      </div>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={3}
      style={{ marginBottom: 50, padding: "20px 20px 200px 20px" }}
    >
      <Grid item>
        <Typography variant="h5">{event ? event.name : ""}</Typography>
      </Grid>
      {pairings.map((pairing) => {
        return (
          <Grid key={pairing._id} item style={{ marginBottom: 20 }}>
            <Card sx={{ width: 345 }}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={1}
                style={{ padding: 20 }}
              >
                <Grid item>
                  <Typography gutterBottom variant="h6" component="div">
                    Round {pairing.round}
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar
                    src={pairing?.profileImageUrl}
                    style={{ width: 120, height: 120 }}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body1" component="div">
                    {pairing.firstName}
                  </Typography>
                </Grid>
                <Grid item style={{display:'flex', direction: 'row', justifyContent: 'center'}}>
                  <Typography gutterBottom variant="body1" component="div">
                    Match:
                  </Typography>
                  {pairing?.match ? <CheckIcon style={{color: "#68C371"}}/> : <CloseIcon style={{color: "#E36262"}}/>}
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2" component="div">
                    {pairing?.notes}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default EventResults;
