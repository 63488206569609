import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Radio,
} from "@mui/material";

import request from "../../../../utilities/requests";

const YesNoQuestion = ({ questionData, next }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [checked, setChecked] = React.useState(null);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData)
    }
  }, [questionData])


  const handleSave = async () => {
    const body = {
      questionId: question._id,
      answer: checked
    }
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      next();
      setChecked(null);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChecked("yes")}
        >
          <Typography variant="h6">Yes</Typography>
          <Radio checked={checked === "yes" ? true : false} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChecked("no")}
        >
          <Typography variant="h6">No</Typography>
          <Radio checked={checked === "no" ? true : false} />
        </Paper>
      </Grid>
      {checked ? ( <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: 20,
          left: 0,
          display: "flex",
          alignItems: "center",
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Button variant="contained" style={{ width: "100%" }} onClick={handleSave}>
          next
        </Button>
      </div>) : <></>}
     
    </Grid>
  );
};

export default YesNoQuestion;
