import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import parsePhoneNumber from "libphonenumber-js";
import { updatePhone } from "../../../utilities/authFlow";

import request from "../../../utilities/requests";

const EditPhone = ({ open, close, success }) => {
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState("");


  const validatePhone = () => {
    console.log("validating phone");
    if (!phone) {
      setError(true);
      return false;
    }
    const phoneNumber = parsePhoneNumber(phone, "US");
    if (!phoneNumber.isValid()) {
      console.log("Invalid phone");
      setError(true);
      return false;
    }
    if (phoneNumber.isValid() && error) {
      setError(null);
    }
    return true;
  };

  const handleUpdate = async () => {

    //if code is not showing, first validate change the email
    if (!validatePhone(phone)) {
      return;
    }

    const phoneNumber = parsePhoneNumber(phone, "US");

    const aResult = await updatePhone(phoneNumber.number);
    if (!aResult.success) {
      alert(aResult.error);
      return
    }
    let bResult = await request("profile/update", "post", { phone: phoneNumber.number });
        if (!bResult.success) {
          alert(bResult.error);
          return
        }
        success("Phone");
        setPhone("");
    return;
  };

  const formatPhone = () => {

  }

  const handleClose = () => {
    close();
    setError(null)
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Edit Phone</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter a new mobile number</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Number"
          fullWidth
          variant="standard"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onBlur={validatePhone}
          error={error ? error : false}
          helperText={
            error ? "Please enter a valid phone number" : ""
          }
          placeholder="1234567890"
          inputProps={{ inputMode: 'numeric' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdate}>Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPhone;
