import React from "react";
import ReactGA from 'react-ga4';
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";

import FirstView from "./components/FirstView";
import Tier from "./components/Tier";
import CategoryCards from "./components/CategoryCards";
import AnswersDialog from "./components/AnswersDialog";

import request from "../../utilities/requests";

const Matchmaking = () => {
  const [tier, setTier] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [stats, setStats] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [filteredQuestions, setFilteredQuestions] = React.useState([]);
  const [dialogAction, setDialogAction ] = React.useState(null);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/matchmaking", title: "Matchmaking" });
  },[])

  React.useEffect(() => {
    if (!tier) {
      getTier();
    }
    if (!questions && tier > 0) {
      getQuestions();
    }
  }, [tier]);

  const getTier = async () => {
    const result = await request("matchmaking/tier");
    if (result.success) {
      setTier(result.matchmakingTier);
    }
  };

  const getQuestions = async () => {
    const result = await request(
      `matchmaking/questions?groupByCategory=true&stats=true`
    );
    if (result.success) {
      setQuestions(result.questions);
      setStats(result.stats);
    }
  };

  const handleCardClick = (action, category) => {
    const filtered = questions.filter((q) => q.category === category);
    setFilteredQuestions(filtered[0].questions);
    setDialogAction(action);
    setDialogOpen(true);
  };

  if (tier === null) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ height: 100, width: 100 }} />
      </div>
    );
  } else if (tier === 0) {
    return <FirstView refresh={() => getTier()} />;
  } else if (tier > 0) {
    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Tier tier={tier} />
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }} xs={12}>
          <CategoryCards
            stats={stats}
            handleClick={(action, category) =>
              handleCardClick(action, category)
            }
          />
        </Grid>
        <Grid item style={{height: 100}} />
        <AnswersDialog
          open={dialogOpen}
          close={() => {
            getQuestions();
            setDialogOpen(false);
            setFilteredQuestions([]);
          }}
          questions={filteredQuestions}
          action={dialogAction}
        />
      </Grid>
    );
  }
};

export default Matchmaking;
