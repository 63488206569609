import React from "react";
import { Typography } from "@mui/material";

const PairingInfo = ({ pairing }) => {
  const [data, setData] = React.useState({ name: "", table: "" });

  React.useEffect(() => {
    if (pairing) {
      setData({
        name: pairing.person?.profile?.firstName,
        table: pairing.table,
        round: pairing.round,
      });
    }
  }, [pairing]);

  if (!data.name && !data.round) {
    return <></>;
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          style={{ fontWeight: "bold" }}
        >
          {data?.name}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography>Round: {data?.round ? data.round : ""}</Typography>
        {pairing?.noPairing ? (
          <></>
        ) : (
          <Typography>Table: {data?.table ? data.table : ""}</Typography>
        )}
      </div>
    </div>
  );
};

export default PairingInfo;
