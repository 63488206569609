import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Radio,
} from "@mui/material";

import request from "../../../../utilities/requests";

const YesNoQuestion = ({ questionData }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [checked, setChecked] = React.useState(null);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData);
      setChecked(questionData?.answer?.value);
    }
  }, [questionData])


  const handleSave = async () => {
    const body = {
      questionId: question._id,
      answer: checked
    }
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      let q = question;
      q.answer.value = checked;
      setQuestion({...q});
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChecked("yes")}
        >
          <Typography variant="body1">Yes</Typography>
          <Radio checked={checked === "yes" ? true : false} />
      </Grid>
      <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChecked("no")}
        >
          <Typography variant="body1">No</Typography>
          <Radio checked={checked === "no" ? true : false} />
      </Grid>
      {question?.answer?.value === checked ? (
        <></>
      ) : (
        <Grid item xs={10}>
          <Button
            variant="contained"
            style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            onClick={handleSave}
          >
            save
          </Button>
        </Grid>
      )}
   
     
    </Grid>
  );
};

export default YesNoQuestion;
