import React from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import YesNoQuestion from "./questions/YesNoQuestion";
import ChoiceQuestion from "./questions/ChoiceQuestion";
import RangeQuestion from "./questions/RangeQuestion";
import TextQuestion from "./questions/TextQuestion";

const AnswerQuestions = ({ questions, handleDone }) => {
  const [unanswered, setUnanswered] = React.useState(null);
  const [activeQuestion, setActiveQuestion] = React.useState(null);

  React.useEffect(() => {
    if (unanswered === null) {
      const u = questions.filter((q) => !q.answer);
      setUnanswered(u);
      setActiveQuestion(0);
    }
  }, [questions]);

  const renderQuestion = () => {
    try {
      if (activeQuestion !== null) {
        return unanswered[activeQuestion].question;
      }
    } catch (e) {
      return "";
    }
  };

  const renderAnswers = () => {
    switch (unanswered[activeQuestion].questionType) {
      case "yesno":
        return <YesNoQuestion questionData={unanswered[activeQuestion]} next={handleNext}/>;
      case "choice":
        return <ChoiceQuestion questionData={unanswered[activeQuestion]} next={handleNext}/>;
      case "range":
        return <RangeQuestion questionData={unanswered[activeQuestion]} next={handleNext}/>;
      case "text":
        return <TextQuestion questionData={unanswered[activeQuestion]} next={handleNext}/>;
      default:
        return <></>;
    }
  };

  const handleNext = () => {
    let q = activeQuestion + 1;
    if ((q + 1) > unanswered.length){
        handleDone();
    } else {
        setActiveQuestion(q);
    }
  }

  const handleSave = async () => {};

  return (
    <Grid container style={{height: '100%'}} spacing={5}>
      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h5" fontWeight="bold">
          {renderQuestion()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {activeQuestion !== null ? renderAnswers() : <></>}
      </Grid>
    </Grid>
  );
};

export default AnswerQuestions;
