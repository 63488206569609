import React from "react";
import { Grid, Typography, Paper, Button, Radio } from "@mui/material";

import request from "../../../../utilities/requests";

const ChoiceQuestion = ({ questionData, index }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [choice, setChoice] = React.useState(null);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData);
      setChoice(questionData?.answer?.value);
    }
  }, [questionData]);

  const handleSave = async () => {
    const body = {
      questionId: question._id,
      answer: choice,
    };
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      let q = question;
      q.answer.value = choice;
      setQuestion({...q});
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      {question?.choice1 ? (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setChoice(question?.choice1)}
        >
          <Typography variant="body1">{question?.choice1}</Typography>
          <Radio checked={choice === question?.choice1 ? true : false} />
        </Grid>
      ) : (
        <></>
      )}
      {question?.choice2 ? (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChoice(question?.choice2)}
        >
          <Typography variant="body">{question?.choice2}</Typography>
          <Radio checked={choice === question?.choice2 ? true : false} />
        </Grid>
      ) : (
        <></>
      )}
      {question?.choice3 ? (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChoice(question?.choice3)}
        >
          <Typography variant="body1">{question?.choice3}</Typography>
          <Radio checked={choice === question?.choice3 ? true : false} />
        </Grid>
      ) : (
        <></>
      )}
      {question?.choice4 ? (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChoice(question?.choice4)}
        >
          <Typography variant="body1">{question?.choice4}</Typography>
          <Radio checked={choice === question?.choice4 ? true : false} />
        </Grid>
      ) : (
        <></>
      )}
      {question?.choice5 ? (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setChoice(question?.choice5)}
        >
          <Typography variant="body1">{question?.choice5}</Typography>
          <Radio checked={choice === question?.choice5 ? true : false} />
        </Grid>
      ) : (
        <></>
      )}
      {question?.answer?.value === choice ? (
        <></>
      ) : (
        <Grid item xs={10}>
          <Button
            variant="contained"
            style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            onClick={handleSave}
          >
            save
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ChoiceQuestion;
