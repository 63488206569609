import React from "react";
import { Grid, Typography, Paper, Button, Radio, Slider } from "@mui/material";

import request from "../../../../utilities/requests";

const RangeQuestion = ({ questionData, next }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [value, setValue] = React.useState(50);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData)
    }
  }, [questionData])

  const handleChange = (val) => {

    setValue(val);
    if (!changed) {
      setChanged(true);
    }

  }

  const handleSave = async () => {

    let val = Math.round(value / 10);
    if (val === 0) {
      val = 1;
    }
    const body = {
      questionId: question._id,
      answer: value,
    };
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      next();
      setChanged(false);
      setValue(50);
    }
  };

  return (
    <Grid container justifyContent="center">
       <Grid item xs={10}>
       <Slider step={10} value={value} track={false} onChange={(e) => handleChange(e.target.value)}/>
      </Grid>
      <Grid item style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} xs={12}>
        <Typography variant="h6">{question ? question.lowerRangeLabel : ""}</Typography>
        <Typography variant="h6">{question ? question.upperRangeLabel : ""}</Typography>
      </Grid>
     

      {changed ? (
        <div
          style={{
            width: "100%",
            position: "fixed",
            bottom: 20,
            left: 0,
            display: "flex",
            alignItems: "center",
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Button
            variant="contained"
            style={{ width: "100%" }}
            onClick={handleSave}
          >
            next
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default RangeQuestion;
