import React from "react";
import { Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "../styles.css";

const RoundController =  ({pairing, feedback}) => {

    const handleClick = (btn) => {
        feedback(btn)
    }


    return (
        <Grid container direction="row" style={{height: 100}} alignItems="center" >
            
            <Grid item xs={12}>
                <Typography variant="h4" align="center" style={{fontWeight: "bold"}}>Round {pairing ? pairing.round : ""}</Typography>
            </Grid>
           
        </Grid>
    )
}

export default RoundController