import React from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import ChoiceQuestion from "./answers/ChoiceQuestion";
import YesNoQuestion from "./answers/YesNoQuestion";
import RangeQuestion from "./answers/RangeQuestion";
import TextQuestion from "./answers/TextQuestion";

const ViewAnswers = ({ questionData }) => {
  const [questions, setQuestions] = React.useState(questionData);

  const renderCards = () => {
    if (!questions) {
      return <></>;
    }
    return questions.map((q, i) => {
      if (q.answer) {
        return (
          <Grid item key={q._id} xs={12}>
            <Paper elevation={2} style={{ padding: 10 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
                    {q.question}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                    {renderAnswer(q, i)}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      }
    });
  };

  const renderAnswer = (q, i) => {

        switch (q.questionType) {
          case "yesno":
            return <YesNoQuestion questionData={q} />;
          case "choice":
            return <ChoiceQuestion questionData={q} />;
          case "range":
            return <RangeQuestion questionData={q} />;
          case "text":
            return <TextQuestion questionData={q} />;
          default:
            return <></>;
        }
      




  }

  return (
    <Grid container spacing={3}>
      {renderCards()}
    </Grid>
  );
};

export default ViewAnswers;
