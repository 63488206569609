import React from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AnswerQuestions from "./AnswerQuestions";
import ViewAnswers from "./ViewAnswers";

import request from "../../../utilities/requests";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AnswersDialog = ({ open, close, questions, action }) => {
  const renderTitle = () => {
    switch (action) {
      case "answerQuestions":
        return "Matchmaking Questions";
      case "viewAnswers":
        return "Review and Edit Answers";
      default:
        return "";
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {renderTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container direction="column" spacing={3} style={{ padding: 20, height: '100%' }}>
          <Grid item style={{height: '100%'}}>
            {action === "answerQuestions" ? (
              <AnswerQuestions questions={questions} handleDone={close}/>
            ) : (
              <ViewAnswers questionData={questions} />
            )}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AnswersDialog;
