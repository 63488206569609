import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const HelpDialog = ({open, close}) => {



    return (
        <Dialog
        open={open}
        onClose={() =>  close()}
      >
        <DialogTitle id="alert-dialog-title">
          {"Here's how it works"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            The event consists of multiple rounds. Each round you will go to a different table to meet a new match. 
          </DialogContentText>
          <br />
          <DialogContentText >
            On this page, you will see the current round at the top and then with in the box the name of your match and the table where you will be meeting them.
          </DialogContentText>
          <br />
          <DialogContentText >
            Once the round it over, click on the green heart if you liked your match and would like to be connected with them or click on the red X if is not the case. You can always change your selection.
          </DialogContentText>
          <br />
          <DialogContentText >
            Once you have made your decision on your match, click on the right arrow to move to the next round and see where you will meet your next match. You can always move back to previous rounds if you want to change your decision later on. 
          </DialogContentText>
          <br />
          <DialogContentText >
            If you still have questions, please do not hestiate to ask an event host.
          </DialogContentText>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>  close()} autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    )

}

export default HelpDialog;