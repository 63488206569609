import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import request from "../../../utilities/requests";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Typography,
  Stack,
  Chip
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import InfoDialog from "./InfoDialog";
import ConfirmRedirectDialog from "./ConfirmRedirectDialog";

import { AppContext } from "../../../contexts/AppContext";
import CheckInDialog from "./CheckInDialog";

import "../events.css";

const MyEvents = () => {
  const navigate = useNavigate();
  const { setActiveEvent, myEvents, setMyEvents } =
    React.useContext(AppContext);
  // const [events, setEvents] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [eventUrl, setEventUrl] = React.useState(null);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [checkInOpen, setCheckInOpen] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/myevents", title: "MyEvents" });
  },[])

  React.useEffect(() => {
    if (!loaded) {
      getMyEvents();
      setLoaded(true);
      window.scrollTo(0,0);
    }
  });

  // React.useEffect(() => {
  //   if (myEvents) {
  //     for (var event of myEvents) {
  //       console.log("Setting socket listener for event start");
  //       socket.on(`${event._id}-eventstarting`, (data) => {
  //         new Notification("The event is starting!");
  //         console.log("Event starting");
  //         getMyEvents();
  //       });
  //     }
  //   }
  // }, [myEvents]);

  const getMyEvents = async () => {
    console.log("retrieving my events");
    const result = await request("events?registered=true");

    if (result.success) {
      setMyEvents([...result.events]);
    }
  };

  const handleOpen = (url) => {
    setOpen(true);
    setEventUrl(url);
  };
  const handleClose = () => {
    setOpen(false);
    setEventUrl(null);
  };

  const handleCloseInfo = () => {
    setInfoOpen(false);
  };

  const handleOpenLink = () => {
    window.open(eventUrl, "_blank", "noreferrer");
    setOpen(false);
    setEventUrl(null);
  };

  const handleCheckIn = async (event) => {
    const result = await request("events/checkin", "post", {
      eventId: event._id,
    });
    if (!result.success) {
      debugger;
      console.error(result.error);
      alert(result.error);
      return;
    }
    await getMyEvents();
    setCheckInOpen(true);
    setActiveEvent(event);
  };

  const handleCheckInClose = () => {
    setCheckInOpen(false);
  };

  const handleGo = (eventId) => {};

  const formatDate = (date) => {
    let d = new Date(date);
    let dateString = d.toDateString();
    let hours = d.getHours();
    let minutes = d.getMinutes();

    let timeString;
    if (hours > 12 && minutes === 0) {
      timeString = `${hours - 12} PM`;
    } else if (hours > 12 && minutes > 0) {
      timeString = `${hours - 12}:${minutes} PM`;
    } else if (hours <= 12 && minutes === 0) {
      timeString = `${hours} AM`;
    } else if (hours <= 12 && minutes > 0) {
      timeString = `${hours}:${minutes} AM`;
    }

    return `${dateString} @ ${timeString}`;
  };

  const renderCardContent = (event) => {
    if (event.status === "completed") {
      return (
        <>
         <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(event.date)}
          </Typography>
          <Typography align="center" style={{marginTop: 10}}>Event Completed</Typography>
        </>
      );
    }
    if (!event.checkedIn) {
      return (
        <>
          <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(event.date)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {event.description}
          </Typography>
        </>
      );
    } else if (event.checkedIn && event.status !== "active") {
      return (
        <>
          <Typography variant="subtitle2" style={{ color: "#6e0101" }}>
            {formatDate(event.date)}
          </Typography>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div> */}
        </>
      );
    } else if (event.checkedIn && event.status === "active") {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckCircleOutlineIcon className="event-ready-chk" />
        </div>
      );
    }
  };

  const renderButtons = (event) => {
    if (event.status === "scheduled") {
      return <Button onClick={() => setInfoOpen(true)}>Info</Button>;
    } else if (
      ["pending", "active"].indexOf(event.status) > -1 &&
      !event.checkedIn
    ) {
      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleCheckIn(event)}
          disabled={event.checkedIn ? true : false}
        >
          Check In
        </Button>
      );
    } else if (event.status === "pending" && event.checkedIn) {
      return (
        <Button
          // variant="outlined"
          size="small"
        >
          Event Starting Soon!
        </Button>
      );
    } else if (event.status === "active" && event.checkedIn) {
      // setActiveEvent(event);
      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => navigate("/activeevent")}
        >
          Go To Event
        </Button>
      );
    } else if (event.status === 'completed') {
      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => navigate("/eventresults", {state: {event}})}
        >
          View Results
        </Button>
      );
    }
    //TODO: work on this. Will need to update the view as there is no active event page to load
    // else if (event.status === 'completed') {
    //   return (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       onClick={() => navigate("/activeevent")}
    //     >
    //       Review Results
    //     </Button>
    //   )
    // }
  };

  const renderEvents = () => {
    if (myEvents.length === 0) {
      return (
        <Grid item>
          <Typography align="center">
            You have not signed up for any events yet
          </Typography>
        </Grid>
      );
    }

    return myEvents.map((event) => {
      // console.log(event.date)
    
      if (!showAll && (event.status === "completed" || event.status === "canceled")) {
        return <div key={event._id} />
      }
      return (
        <Grid key={event._id} item style={{marginBottom: 20}}>
          <Card sx={{ width: 345 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={event.imageUrl}
              title={event.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {event.name}
              </Typography>
              {renderCardContent(event)}
            </CardContent>
            <CardActions style={{ justifyContent: "space-between" }}>
              <Button
                size="small"
                onClick={() => handleOpen(event.eventbriteUrl)}
                disabled={event.checkedIn ? true : false}
              >
                Update/Cancel
              </Button>
              {renderButtons(event)}
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      // justifyContent="center"
      // spacing={3}
      style={{ marginBottom: 50, paddingBottom: 200, width: '100%' }}
    >
      <Grid item style={{marginBottom: 20}}>
          <Chip label="Show All" variant={showAll  ? "" : "outlined"} color="primary" onClick={() => setShowAll(!showAll)}/>
      </Grid>
      {renderEvents()}
      <ConfirmRedirectDialog
        open={open}
        close={handleClose}
        confirm={handleOpenLink}
      />
      <InfoDialog open={infoOpen} close={handleCloseInfo} />
      <CheckInDialog open={checkInOpen} close={handleCheckInClose} />
    </Grid>
  );
};

export default MyEvents;
