import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import request from "../../../utilities/requests";

const NotesDialog = ({ open, close, pairing }) => {
  const [notes, setNotes] = React.useState("");

  React.useEffect(() => {
    if (pairing && pairing?._id) {
      getNotes();
    }
  }, [pairing]);

  const getNotes = async () => {
    const result = await request(`pairing/notes?pairingId=${pairing._id}`);
    if (result.success) {
      if (result?.notes?.text){
        setNotes(result?.notes?.text);
      } else {
        setNotes("");
      }
    }
  };

  const saveNotes = async () => {
    if (notes === "") {
      close();
      return
    }
    const body = {
      pairingId: pairing._id,
      notes
    }
    const result = await request('pairing/notes', "post", body);
    if (!result?.success) {
      alert(result.error);
    }
    close()
  }

  return (
    <Dialog open={open} onClose={() => close()} fullWidth>
      <DialogTitle id="alert-dialog-title">{"Date notes"}</DialogTitle>
      <DialogContent>
        <TextField
          label="notes"
          multiline
          rows={10}
          fullWidth
          style={{ marginTop: 10 }}
          value={notes}
          onChange={(e) =>  setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          Cancel
        </Button>
        <Button onClick={() => saveNotes()} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesDialog;
