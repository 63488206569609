import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { updateEmail, validateEmailCode } from "../../../utilities/authFlow";

import request from "../../../utilities/requests";

const EditEmail = ({ open, close }) => {
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(null);
  const [emailDisabled, setEmailDisabled] = React.useState(false);
  const [showCode, setShowCode] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [codeVerified, setCodeVerified] = React.useState(0); //0=not tried, 1=success, 2=failure
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleUpdate = async () => {
    if (!validateEmail()) {
      setValidEmail("Please enter a valid email address");
      return;
    }

    const body = { newEmail: email };
    const existsResult = await request("users/checkexists", "post", body);
    if (existsResult.exists) {
      setError(true);
      return;
    }

    //if code is not showing, first validate change the email
    let result;
    if (!showCode) {
      result = await updateEmail(email);
      if (result.success) {
        console.log("a verification code is sent");
        setEmailDisabled(true);
        setShowCode(true);
      } else {
        alert(result.error);
      }
      return;
    }

    //else, verify the code
    result = await validateEmailCode(code, email);
    if (result.success) {
      console.log("email verified");
      setCodeVerified(1);
      setShowSuccess(true);
      let result = await request("profile/update", "post", { email });
      close();
    } else {
      console.error('code error');
      setCodeVerified(2);
    }

  };

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setEmail("");
    setValidEmail(null);
    setShowCode(false);
    setCode("");
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Edit Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will need to confirm your new email
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Email"
          fullWidth
          variant="standard"
          inputProps={{autoCapitalize: 'none'}}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={emailDisabled}
          error={validEmail}
          helperText={validEmail}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Verification Code"
          type="email"
          fullWidth
          variant="standard"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{ display: showCode ? "block" : "none" }}
          error={codeVerified === 2 ? true : null}
          helperText={codeVerified === 2 ? "Invalid code. Please try again" : null}

          inputProps={{ inputMode: 'numeric' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdate}>{showCode ? "verify" : "update"}</Button>
      </DialogActions>
      <Snackbar
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSuccess(false)}
        message="email updated successfully"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ></Snackbar>
      <Dialog open={error} onClose={() => setError(false)}>
        <DialogContent>
          <DialogContentText>
            It looks like there is already a user with the email address {email}
            . This is likely due to your purchasing of the event ticket with
            that email address. <br />
            Here is what you can do:
            <br />
            <br />
            1) You can log out here and register/login with that other email
            <br />
            <br />
            2) You can send an email to{" "}
            <a href="mailto:help@thenextfunthing.com">
              help@thenextfunthing.com
            </a>{" "}
            to have the ticket transferred to this email address
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default EditEmail;
