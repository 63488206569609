import React from "react";
import { Grid, Typography, Paper, Button, Radio } from "@mui/material";

import request from "../../../../utilities/requests";

const ChoiceQuestion = ({ questionData, next }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [choice, setChoice] = React.useState(null);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData);
    }
  }, [questionData]);

  const handleSave = async () => {
    const body = {
      questionId: question._id,
      answer: choice,
    };
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      next();
      setChoice(null);
    }
  };

  return (
    <Grid container spacing={3}>
      {question?.choice1 ? (
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => setChoice(question?.choice1)}
          >
            <Typography variant="h6">{question?.choice1}</Typography>
            <Radio checked={choice === question?.choice1 ? true : false} />
          </Paper>
        </Grid>
      ) : (
        <></>
      )}
       {question?.choice2 ? (
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => setChoice(question?.choice2)}
            >
              <Typography variant="h6">{question?.choice2}</Typography>
              <Radio checked={choice === question?.choice2 ? true : false} />
          </Paper>
        </Grid>
      ) : (
        <></>
      )}
       {question?.choice3 ? (
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => setChoice(question?.choice3)}
            >
              <Typography variant="h6">{question?.choice3}</Typography>
              <Radio checked={choice === question?.choice3 ? true : false} />
          </Paper>
        </Grid>
      ) : (
        <></>
      )}
       {question?.choice4 ? (
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => setChoice(question?.choice4)}
            >
              <Typography variant="h6">{question?.choice4}</Typography>
              <Radio checked={choice === question?.choice4 ? true : false} />
          </Paper>
        </Grid>
      ) : (
        <></>
      )}
             {question?.choice5 ? (
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => setChoice(question?.choice5)}
            >
              <Typography variant="h6">{question?.choice5}</Typography>
              <Radio checked={choice === question?.choice5 ? true : false} />
          </Paper>
        </Grid>
      ) : (
        <></>
      )}

      {choice ? (
        <div
          style={{
            width: "100%",
            position: "fixed",
            bottom: 20,
            left: 0,
            display: "flex",
            alignItems: "center",
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Button
            variant="contained"
            style={{ width: "100%" }}
            onClick={handleSave}
          >
            next
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ChoiceQuestion;
