import React from "react";
import { Grid, Typography, Button } from "@mui/material";

import request from "../../../utilities/requests";

const FirstView = ({ refresh }) => {
  const updateTier = async () => {
    const body = { matchmakingTier: 1 };
    const result = await request("matchmaking/tier", "post", body);
    if (result.success) {
      refresh();
    }
  };

  return (
    <div style={{padding: 20, display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h5" align="center" style={{marginBottom: 40}}>Modern Matchmaking</Typography>

        <Typography align="center" style={{marginBottom: 20}}>
          Dating today can feel like a full time job. Between spending hours
          scrolling through dating apps and showing up to events hoping to meet
          ‘the one,’ sometimes you want to just hit the ‘easy’ button and have
          someone find your next match for you.
        </Typography>

        <Typography  align="center" style={{marginBottom: 20, fontStyle: 'italic', fontWeight: 'bold'}}>That's where we come in</Typography>

        <Typography align="center" style={{marginBottom: 20}}>
          In our extensive database, there are thousands of singles seeking
          something genuine, just like you.
        </Typography>

        <Typography align="center" style={{marginBottom: 20}}>
          If you wish to become part of the singles 'pool' considered for our
          matchmaking services, simply answer the questions on the next page (your Matchmaking Profile).
        </Typography>

        <Typography align="center" style={{marginBottom: 40}}>
          For those desiring a more hands-on approach to finding love, click the
          'upgrade' button on the next page, and one of our matchmakers will
          reach out to you within 24 hours.
        </Typography>

        <Button variant="outlined" onClick={updateTier} style={{marginBottom: 100}}>
          Let's Do This
        </Button>
    </div>
  );
};

export default FirstView;
