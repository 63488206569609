import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const InfoDialog = ({ open, close }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Here's how it works"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText >
          When you arrive to the event, you will see a 'Check In' button on the event card.
          Please click it to check in ONLY when you are at the actual event. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Got it</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
