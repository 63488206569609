import React from "react";
import { Grid, Typography, Paper, Button, Radio, Slider } from "@mui/material";

import request from "../../../../utilities/requests";

const RangeQuestion = ({ questionData }) => {
  const [question, setQuestion] = React.useState(questionData);
  const [value, setValue] = React.useState(questionData?.answer?.value);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (question) {
      setQuestion(questionData)
    }
  }, [questionData])

  const handleChange = (val) => {

    setValue(val);
    if (!changed) {
      setChanged(true);
    }

  }

  const handleSave = async () => {

    let val = Math.round(value / 10);
    if (val === 0) {
      val = 1;
    }
    const body = {
      questionId: question._id,
      answer: value,
    };
    const result = await request("matchmaking/answer", "post", body);
    if (result.success) {
      setChanged(false);
      let q = question;
      q.answer.value = value;
      setQuestion({...q});
    }
  };

  return (
    <Grid container justifyContent="center">
       <Grid item xs={10}>
       <Slider step={10} value={value} track={false} onChange={(e) => handleChange(e.target.value)}/>
      </Grid>
      <Grid item style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} xs={12}>
        <Typography variant="body1">{question ? question.lowerRangeLabel : ""}</Typography>
        <Typography variant="body1">{question ? question.upperRangeLabel : ""}</Typography>
      </Grid>
      {question?.answer?.value === value ? (
        <></>
      ) : (
        <Grid item xs={10}>
          <Button
            variant="contained"
            style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            onClick={handleSave}
          >
            save
          </Button>
        </Grid>
      )}
     

    
    </Grid>
  );
};

export default RangeQuestion;
