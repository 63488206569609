import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import AuthContext from "../../../contexts/AuthContext";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Settings = ({ open, close, success }) => {
  const {setSignedIn, appConfig} = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      localStorage.removeItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
      );
      localStorage.removeItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
      );
      setSignedIn(false);
      navigate("/");
      return
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Settings
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate('/contactus')
              }}
            >
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              {/* </Link> */}
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => window.open(appConfig?.privacyPolicy, '_blank')}
            >
                <ListItemIcon>
                  <PrivacyTipIcon />
                </ListItemIcon>
                <ListItemText primary="Privacy Policy" />
              {/* </Link> */}
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
};

export default Settings;
