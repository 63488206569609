import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Checkbox
} from "@mui/material";

const squareLinks = {
  silver: "https://square.link/u/qptlXL1V",
  gold: "https://square.link/u/Y9CWDmQQ"
}

const ConfirmDialog = ({ open, close, tier }) => {
  const [checked, setChecked] = React.useState(false);
  const handleOpenLink = () => {
   
    window.open(squareLinks[tier], "_blank", "noreferrer");
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Please Review</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Before upgrading, please ensure that you read the <span><a href="https://getloveinla.com/matchmakingterms" target="_blank">General Terms and Conditions</a></span> for Love In LA's matchmaking service. Once you have reviewed the terms, please confirm below. You will then be redirected to an external site (Square) to make payment.
        </DialogContentText>
        <br />
        {/* <DialogContentText>
        I confirm that I have read and undertood Love In LA's General Terms and Conditions for Matchmaking Services. By checking this box I agree to be bound bound by the terms and conditions presented to me.
        </DialogContentText> */}
        <Grid container direction="row" spacing={3} alignItems="center">
          <Grid item xs={1} style={{display: 'flex', justifyContent:'center'}}>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)}/>
          </Grid>
          <Grid item xs={11}>
            <Typography style={{color: 'rgba(0, 0, 0, 0.6)'}}>I confirm that I have read and undertood Love In LA's <span><a href="https://getloveinla.com/matchmakingterms" target="_blank">General Terms and Conditions</a></span> for Matchmaking Services. By checking this box I agree to be bound bound by the terms and conditions presented to me.</Typography>
          </Grid>
        </Grid>
        <div
          style={{ with: "100%", padding: 10,display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => handleOpenLink("silver")}
            disabled={!checked}
          >
            Upgrade to {tier}
          </Button>
        </div>
       
      
      </DialogContent>
     
    </Dialog>
  );
};

export default ConfirmDialog;
