import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AuthContext from "../../../contexts/AuthContext";

const FirstLoginDialog = ({ open, close }) => {
  const { appConfig } = React.useContext(AuthContext);
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please complete your profile"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontWeight: "bold" }}>
          Welcome to {appConfig?.orgName ? appConfig.orgName : "Speed Dating"}!
        </DialogContentText>
        <br />
        <DialogContentText>
          If you are here for speed dating, please complete your 'Speed Dating
          Profile'.
        </DialogContentText>
        <br />
        {process.env.REACT_APP_MATCHMAKING === "true" ? (
          <DialogContentText>
            Your 'Matchmaking Profile' is for matchmaking only - it's free to
            fill to complete and you will be automatically entered into our
            dating pool.
          </DialogContentText>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstLoginDialog;
