import React from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import request from "../../utilities/requests";

import { AppContext } from "../../contexts/AppContext";

const DateSelector = ({ open, close, round }) => {
  const { event } = React.useContext(AppContext);
  const [dates, setDates] = React.useState([]);
  const [selected, setSelected] = React.useState("");
  const [displayMessage, setDisplayMessage] = React.useState(null);

  React.useEffect(() => {
    if (dates.length === 0 && open) {
      getDates();
    }
  }, [dates, open]);

  const getDates = async () => {
    try {
      console.log("Retrieving possible dates");
      const result = await request(
        `pairingevent/wildcardpairings?eventId=${event._id}`
      );
      if (result.success) {
        setDates(result.attendees);
      }
    } catch (e) {
      var error = `Error retrieving a list of possible dates. Error: ${e.message}`;
      console.error(error);
      return;
    }
  };

  const handleSave = async () => {
    try {
      const body = {
        pairId: selected,
        eventId: event,
        round,
      };

      const result = await request("pairing/createwildcard", "post", body);
      if (result.success) {
        alert(result.message);
        close();
        window.location.reload();
      }
    } catch (e) {
      var error = `Error creating wildcard pairing. Error: ${e.message}`;
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelected("");
        close();
      }}
    >
      <DialogTitle id="alert-dialog-title">{"Select Your Date"}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          {displayMessage ? (
            <DialogContentText>{displayMessage}</DialogContentText>
          ) : (
            <>
              <Grid item>
                <DialogContentText>
                  Choose the person you are meeting with the drop down:
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Your Dates Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    label="Your Dates Name"
                    placeholder="Select your Date"
                    style={{ width: "100%" }}
                    onChange={(event) => setSelected(event.target.value)}
                  >
                    {dates.map((d) => {
                      return (
                        <MenuItem key={d.user} value={d.user}>
                          {d?.firstName} {d?.lastName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelected("");
            close();
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSave()}
          autoFocus
          disabled={selected === "" ? true : false}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateSelector;
