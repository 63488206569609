import React from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProfileTextField from "./ProfileTextField";
import ProfileSelectField from "./ProfileSelectField";
import HeightSelectField from "./HeightSelectField";

import request from "../../../utilities/requests";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const textFieldVariant = "standard";

const fieldsToRender = [
  { value: "firstName", label: "First Name" },
  { value: "lastName", label: "Last Name" },
  { value: "pronouns", label: "Pronouns" },
  { value: "interestedIn", label: "I Am Intersted In" },
  { value: "ageRange", label: "My Age Range" },
  { value: "height", label: "Height" },
  { value: "haveChildren", label: "Do You Have Children" },
  { value: "mindChildren", label: "Do You Mind If Your Match Has Children" },
  { value: "location", label: "Location" },
];

const doNotRender = [
  "_id",
  "user",
  "createdAt",
  "updatedAt",
  "__v",
  "username",
  "email",
  "phone",
];

const EditProfile = ({ open, close, profileData, fields, success }) => {
  const [profile, setProfile] = React.useState({});

  React.useEffect(() => {
    setProfile(profileData);
  }, [profileData]);

  const handleChange = (field, value) => {
    setProfile({
      ...profile,
      [field]: value,
    });
  };

  const handleSave = async () => {
    const doNotInclude = ['user','_id', 'createdAt', 'updatedAt', '__v', 'username', 'email', 'phone','firstLogin'];
    let body = {};
    for (var field of Object.keys(profile)) {
      if (doNotInclude.indexOf(field) === -1) {
        body[field] = profile[field]
      }
    }
    let result = await request("profile/update", "post", body);
    if (result.success) {
      success("Profile");
    }
    close();
  };

  const renderFields = () => {
    if (Object.keys(profile).length === 0) {
      return <></>;
    }

    return fieldsToRender.map((field) => {
      if (
        fields[field.value].instance === "String" &&
        !fields[field.value].options?.enum
      ) {
        return (
          <ProfileTextField
            key={field.value}
            name={field.value}
            label={field.label}
            value={profile[field.value]}
            handleChange={handleChange}
          />
        );
      } else if (
        fields[field.value].instance === "String" &&
        fields[field.value].options?.enum
      ) {
        return (
          <ProfileSelectField
            key={field.value}
            name={field.value}
            label={field.label}
            value={profile[field.value]}
            options={fields[field.value].enumValues}
            handleChange={handleChange}
          />
        );
      } else {
        return <div key={field}></div>;
      }
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Profile
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container direction="column" spacing={3} style={{ padding: 20 }}>
          {renderFields()}
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleSave}
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default EditProfile;
