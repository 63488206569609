import React from 'react';

const AuthContext = React.createContext({
    signedIn: false,
    setSignedIn: (event) => {},
    email: "",
    setEmail: (event) => {},
    token: null,
    setToken: (event) => {},
    appStyle: {},
    appConfig: {},
    orgId: null
})

export default AuthContext;