import React from "react";

import { Grid, TextField } from "@mui/material";

const ProfileTextField = ({ name, label, value, handleChange }) => {
  const convertLabel = (text) => {
    try {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    } catch (e) {
      return text;
    }
  };

  return (
    <Grid item>
      <TextField
        // variant={textFieldVariant}
        fullWidth
        label={label}
        value={value}
        onChange={(e) => handleChange(name, e.target.value)}
      />
    </Grid>
  );
};

export default ProfileTextField;
