import React from "react";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const ProfileSelectField = ({ name, label, value, options, handleChange }) => {
  const convertLabel = (text) => {
    try {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    } catch (e) {
      debugger;
      return text;
    }
  };

  return (
    <Grid item>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          label={label}
          onChange={(e) => handleChange(name, e.target.value)}
        >
          {options.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default ProfileSelectField;
