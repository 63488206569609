import React from "react";
import { BrowserRouter, Routes, Route, HashRouter, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import AuthContext from "./contexts/AuthContext";
import { AppContext } from "./contexts/AppContext";

import Auth from "./pages/Auth";
import Layout from "./components/Layout";
import Profile from "./pages/Profile";
import Matchmaking from "./pages/Matchmaking/Matchmaking";
import Events from "./pages/Events";
import MyEvents from "./pages/Events/components/MyEvents";
import ActiveEvent from "./pages/ActiveEvent/ActiveEvent";
import EventResults from "./pages/Events/components/EventResults";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy/Privacy";

const Router = () => {
  const { signedIn, appStyle } = React.useContext(AuthContext);
  const [event, setActiveEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [myEvents, setMyEvents] = React.useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: appStyle?.primaryColor ? appStyle.primaryColor : "#ef5241",
      },
    },
    typography: {
      fontFamily: appStyle?.fontFamily ? appStyle.fontFamily.join(",") : ""
    },
  });

  if (signedIn) {
    return (
      <HashRouter>
        <AppContext.Provider
          value={{
            event,
            setActiveEvent,
            // socket,
            events,
            setEvents,
            myEvents,
            setMyEvents,
          }}
        >
          <ThemeProvider theme={theme}>
            <Layout>
              <Routes>
                <Route index path="/" element={<MyEvents />} />
                <Route path="/events" element={<Events />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/matchmaking" element={<Matchmaking />} />
                <Route path="/activeevent" element={<ActiveEvent />} />
                <Route path="/eventresults" element={<EventResults />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
            </Layout>
          </ThemeProvider>
        </AppContext.Provider>
      </HashRouter>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Auth />
    </ThemeProvider>
  );
};

export default Router;
