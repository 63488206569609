import React from "react";
import {
  Grid,
  Paper,
  List,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Divider,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";

import EditProfile from "./EditProfile";
import EditEmail from "./EditEmail";
// import EditUsername from "./EditUsername";
import EditPhone from "./EditPhone";
import Settings from "./Settings";
import request from "../../../utilities/requests";

const iconSize = 100;

const ProfileBody = ({ profile, fields, refresh }) => {
  const navigate = useNavigate();
  const [editOpen, setEditOpen] = React.useState(false);
  const [editEmailOpen, setEditEmailOpen] = React.useState(false);
  const [editUsernameOpen, setEditUsernameOpen] = React.useState(false);
  const [editPhoneOpen, setEditPhoneOpen] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditEmailClose = () => {
    setEditEmailOpen(false);
    refresh();
  };

  const handleEditPhoneClose = () => {
    setEditPhoneOpen(false);
    refresh();
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleSnackbar = (field) => {
    setSuccessMessage(`${field} updated successfully`);
    setShowSuccess(true);
    refresh();
    setEditEmailOpen(false);
    setEditPhoneOpen(false);
  };

  const handleEditReceiveTexts = async (event) => {
    const val = event.target.checked;
    const body = {
      receiveEventTexts: val,
    };
    const result = await request("profile/update", "post", body);
    if (!result.success) {
      alert(result.error);
    }
    refresh();
  };

  return (
    <Grid
      item
      style={{
        height: 200,
        width: "100%",
      }}
    >
      <Grid container direction="column" spacing={3} style={{ padding: 20 }}>
        <Grid item>
          <Paper height={3}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  User Details
                </ListSubheader>
              }
            >
              <ListItemButton onClick={() => setEditEmailOpen(true)}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={profile ? profile.email : ""}
                  secondary="email"
                />
              </ListItemButton>
              <Divider />
              {/* <ListItemButton>
                <ListItemIcon>
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={profile ? profile.username : "username"}
                  secondary="username"
                />
              </ListItemButton>
              <Divider /> */}
              <ListItemButton onClick={() => setEditPhoneOpen(true)}>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary={profile ? profile.phone : ""}
                  secondary="mobile number"
                />
              </ListItemButton>
              {/* <Divider /> */}
              {/* <ListItem style={{ marginBottom: -10 }}>
                <FormControl>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={profile ? profile.receiveEventTexts : false}
                        onChange={handleEditReceiveTexts}
                      />
                    }
                    label="Receive In-Event Texts"
                    labelPlacement="start"
                  />
                </FormControl>
              </ListItem> */}
            </List>
          </Paper>
        </Grid>
        <Grid item>
          <Paper height={3}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleEditOpen}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Speed Dating Profile" />
              </ListItemButton>
              {process.env.REACT_APP_MATCHMAKING === "true" ? (
                <ListItemButton onClick={() => navigate("/matchmaking")}>
                  <ListItemIcon>
                    <FavoriteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Matchmaking Profile" />
                </ListItemButton>
              ) : (
                <></>
              )}

              <ListItemButton onClick={handleSettingsOpen}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Paper>
        </Grid>
      </Grid>
      <EditEmail open={editEmailOpen} close={handleEditEmailClose} />
      <EditPhone
        open={editPhoneOpen}
        close={handleEditPhoneClose}
        success={(field) => handleSnackbar(field)}
      />
      {/* <EditUsername 
        open={editUsernameOpen}
        close={handleEditUsernameClose}
      /> */}
      <EditProfile
        open={editOpen}
        close={handleEditClose}
        profileData={profile}
        fields={fields}
        success={(field) => handleSnackbar(field)}
      />
      <Settings open={settingsOpen} close={handleSettingsClose} />
      <Snackbar
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSuccess(false)}
        message={successMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ></Snackbar>
    </Grid>
  );
};

export default ProfileBody;
