import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmRedirectDialog = ({ open, close, confirm }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Go...But Come Back"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText >
          You will be redirected to Eventbrite to complete your registration.\n
          Please be sure to register with the same email. Once registered, come back here, and you
          should see the event under the 'My Events' tab.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={confirm} autoFocus>
          Let's Go!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRedirectDialog;
