import React from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as EmailValidator from "email-validator";
import AuthContext from "../../../contexts/AuthContext";
import { confirm } from "../../../utilities/authFlow";


const Confirm = ({ setPage }) => {
  const theme = useTheme();
  const { email, setEmail, appStyle, appConfig } = React.useContext(AuthContext);
  const [code, setCode] = React.useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [error, setError] = React.useState("");

  const validateEmail = () => {
    console.log("validating email");
    const valid = EmailValidator.validate(email);
    if (!valid) {
      setEmailError(true);
      return false
    }

      setEmailError(null);
      return true

  };

  const handleConfirm = async () => {
    if (!validateEmail()) {
      setEmailError(true);
      return;
    }
    setError(null);
    const result = await confirm(email, code);
    if (result.success) {
        console.log('confirmed!');
        setPage('success');

    } else {
        setError(result.error);
    }

  };

  const handleSendNewCode = () => {};

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <img src={appStyle?.logo} height="175px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <form style={{ display: "flex", justifyContent: "center" }}> */}
        <Paper elevation={3} style={{ width: "100%", padding: 10 }}>
          <Grid container direction="column" spacing={3}>
            <Grid item style={{ marginTop: 20, marginBottom: 0 }}>
              <Typography variant="h5" align="center">
                Enter your confirmation code
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 0, marginBottom: 20 }}>
              <Typography variant="subtitle2" align="center">
                Please check your email for a 6 digit code. Be sure to check
                your spam folder as well. It may take a few minutes to arrive.
              </Typography>
            </Grid>
            {error ? (
              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.main }}>
                  {error}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
                style={{ width: "90%" }}
                sx={{
                  fieldset: { borderColor: "red" },
                }}
                autoComplete="off"
                onBlur={validateEmail}
                error={emailError ? true : false}
                helperText={emailError ? "Please enter a valid email" : ""}
              />
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                autoFocus
                margin="dense"
                label="Code"
                variant="outlined"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter your code"
                style={{ width: "90%" }}
                autoComplete="off"
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ width: "90%", height: 40, backgroundColor: theme.palette.primary.main }}
                onClick={handleConfirm}
              >
                confirm
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 0, marginBottom: 0 }}>
              <Typography
                variant="subtitle2"
                align="center"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                No code? No problem. Click{" "}
                <span
                  onClick={handleSendNewCode}
                  style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                >
                  here
                </span>{" "}
                to send a new one.
              </Typography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ width: "90%", height: 40, color: theme.palette.primary.main }}
                onClick={() => setPage("login")}
              >
                back to login
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {/* </form> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography align="center" style={{ color: "#353535" }}>
              &copy; Aisle 2 LLC All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="center"
              style={{ color: "#353535", marginBottom: 50 }}
              onClick={() => window.open(appConfig?.privacyPolicy, '_blank')}

            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Confirm;
